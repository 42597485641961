import baseUrl from '../baseUrl'
export default {
  getDevices: baseUrl + '/v1/dst/device',
  getIncomeData: baseUrl + '/v1/dst/income',
  getDevDetailInfo: baseUrl + '/v1/dst/dev-info',
  getMoneyDetail: baseUrl + '/v1/cash/index',
  getMoneyNum: baseUrl + '/v1/cash/money',
  submitMoney: baseUrl + '/v1/cash/cash',
  getRevenue: baseUrl + '/v1/dst/revenue-list',
  editPrice: baseUrl + '/v1/dst/edit-price',
  getBindInfo: baseUrl + '/v1/dst/bind-info',
  smsCode: baseUrl + '/v1/site/sms-code',
  unbind: baseUrl + '/v1/dst/unbind',
  getExistCity: baseUrl + '/v1/city/city',
  getExistExaRoom: baseUrl + '/v1/dst/dev-field',
  syncLine: baseUrl + '/v1/dst/field-sync',
  getField: baseUrl + '/v1/dst/field',
  actField: baseUrl + '/v1/dst/field-log',
  signIn: baseUrl + '/v1/login/device-login',
  delayExp: baseUrl + '/v1/dst/device-exp',
  editMountStatus: baseUrl + '/v1/dst/edit-mount-status',
  stuLoginedList: baseUrl + '/v1/dst/device-member-list',
  deviceOrders: baseUrl + '/v1/dst/device-order-list',
  // deviceManager: baseUrl + '/v1/practice/device-manager',
  deviceManager: baseUrl + '/v1/practice/device',
  deviceManEdit: baseUrl + '/v1/practice/device-manager-edit',
  practiceList: baseUrl + '/v1/practice/practice-list',
  practiceDetail: baseUrl + '/v1/practice/practice-detail',
  bindPractice: baseUrl + '/v1/practice/bind-practice',
  practiceData: baseUrl + '/v1/practice/practice-data',
  practApply: baseUrl + '/v1/practice/apply',
  practiceInfo: baseUrl + '/v1/practice/info',
  createPractOrder: baseUrl + '/v1/practice/create-practice-order',
  practiceTip: baseUrl + '/v1/practice/practice-tip',
  uploadFile: baseUrl + '/v1/common/upload',
}
