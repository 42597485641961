import api from '../index'
import urls from './urls'

const header = {}

export default {
  getOrder(params) {//获取订单列表
    return api.get(urls.getOrder, params, header)

  },
  stuLoginDevice(data,params) {//学员登录设备
    return api.post(urls.stuLoginDevice, params, header, data)

  },
  stuPracticeDriving(data,params) {//手动开始练车
    return api.post(urls.stuPracticeDriving, params, header, data)

  },
  stuDeviceList(params) {//学员设备列表
    return api.get(urls.stuDeviceList, params, header)

  },
  stuTimerProduct(params) {//学员扫码付费产品详情
    return api.get(urls.stuTimerProduct, params, header)

  },
  stuTimerPay(data,params) {//学员扫码付费
    return api.post(urls.stuTimerPay, params, header,data)

  },
  // editPrice(data, params) {//修改设备价格
  //   return api.post(urls.editPrice, params, header, data)
  // },



}

